import { useEffect, useState } from "react";
import jast_logo from "../images/jast_logo.png";
import Radium from "radium";
import { useNavigation } from "@react-navigation/native";

function WebFooter() {
  return (
    <div style={styles.footer}>
      <div style={styles.contact}>
        <div style={{ fontWeight: "bold" }}>KONTAKTNÉ INFORMÁCIE</div>
        <div style={{ paddingTop: 20 }}>Ing. Stanislav Berta - JAST</div>
        <div>Mierová 64/2</div>
        <div>066 01 Humenné</div>
        <div style={{ marginTop: 10 }}>Tel.: +421 (0) 907 595 278</div>
        <div>Email: oknajast@azet.sk</div>
      </div>
    </div>
  );
}

WebFooter = Radium(WebFooter);

const styles = {
  contact: {
    display: "flex",
    alignSelf: "flex-start",
    flexDirection: "column",
    flex: 1,
    marginLeft: "20%", //400,
    fontFamily: "Helvetica Neue",
    color: "#161170",
    marginTop: "1%", //20,
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    height: "10%", //200,
    backgroundColor: "#cccfcd",
    alignText: "flex-end",
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    flex: 1,
    //  marginTop: 275,
    //  paddingRight: 500,
    borderTop: "3px solid #161170",
  },
};

export default WebFooter;
