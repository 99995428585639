import HomeScreen from "./components/HomeScreen";
import PlasticWindowsScreen from "./components/PlasticWindowsScreen";
import PlasticDoorsScreen from "./components/PlasticDoorsScreen";
import AluminumWindowsScreen from "./components/AluminumWindowsScreen";
import AluminumDoorsScreen from "./components/AluminumDoorsScreen";

import { createStackNavigator } from "@react-navigation/stack";
import TerraceDoorsScreen from "./components/TerraceDoorsScreen";
import PriceOfferScreen from "./components/PriceOfferScreen";
import ContactScreen from "./components/ContactScreen";

const Stack = createStackNavigator();

function App() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="Home" component={HomeScreen} />
      <Stack.Screen name="Plastic windows" component={PlasticWindowsScreen} />
      <Stack.Screen name="Plastic doors" component={PlasticDoorsScreen} />
      <Stack.Screen name="Aluminum windows" component={AluminumWindowsScreen} />
      <Stack.Screen name="Aluminum doors" component={AluminumDoorsScreen} />
      <Stack.Screen name="Terrace doors" component={TerraceDoorsScreen} />
      {/* <Stack.Screen name="Price offer" component={PriceOfferScreen} /> */}
      <Stack.Screen name="Contact" component={ContactScreen} />
    </Stack.Navigator>
  );
  {
    /*<HomeScreen></HomeScreen>*/
  }

  {
    /*
   <HomeScreen></HomeScreen>
  
  } <Stack.Navigator>
      <Stack.Screen name="Home" component={HomeScreen} />
  </Stack.Navigator> */
  }
}

export default App;
