import { render } from "@testing-library/react";
import { BrowserView, MobileView } from "react-device-detect";
import WebHeader from "./WebHeader";
import MobileHeader from "./MobileHeader";
import hesta_text from "../images/hesta-logo.png";
import smart_window from "../images/smart_okno.png";
import smartplus_window from "../images/smart+_okno.png";
import harmony_window from "../images/harmony_okno.png";
import harmonyplus_window from "../images/harmony+_okno.png";
import EuroIcon from "@material-ui/icons/EuroSymbol";
import WebFooter from "./WebFooter";

function PlasticWindowsScreen() {
  return (
    <div>
      <BrowserView>
        <WebHeader />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            marginTop: "5%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #161170",
              flex: 1.1,
              marginLeft: "5%",
            }}
          >
            <div
              style={{
                backgroundColor: "#cccfcd",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={hesta_text}
                    style={{ marginTop: 7 }}
                    //  width={windowSize.width - 15}
                    //  height={windowSize.height}
                  />
                </div>
                <div
                  style={{ fontSize: 40, fontWeight: "bold", color: "#161170" }}
                >
                  SMART
                </div>
              </div>
              <div
                style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
              />
              <div style={{ backgroundColor: "white" }}>
                <img src={smart_window} width="100%" />
              </div>
              <div
                style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
              />
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    alignSelf: "flex-start",
                    display: "flex",
                    // flex: 2,
                    fontSize: 20,
                    marginRight: 20,
                    // justifyContent: "space-around",
                  }}
                >
                  Cenová náročnosť
                </div>
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    //  flex: 0.5,
                    // justifyContent: "space-around",
                  }}
                >
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "#161170",
                      //  flex: 1,
                      // alignSelf: "flex-start",
                      //marginRight: "70%",
                    }}
                  ></EuroIcon>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    // flex: 1,
                  }}
                >
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "rgb(164, 165, 166, 1)",
                    }}
                  ></EuroIcon>
                </div>
                <div style={{ display: "flex", alignSelf: "center" }}>
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "rgb(164, 165, 166, 1)",
                    }}
                  ></EuroIcon>
                </div>
                <div style={{ display: "flex", alignSelf: "center" }}>
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "rgb(164, 165, 166, 1)",
                    }}
                  ></EuroIcon>
                </div>
              </div>
              <div
                style={{
                  color: "black",
                  fontSize: 20,
                  alignSelf: "center",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 20,
                  height: 110,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Základný typ odporúčaný najmä tam, kde je rozhodujúca čo
                najnižšia cena.
              </div>
            </div>
            <div
              style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
            />
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flex: 1,
                flexDirection: "column",
                marginLeft: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  //color: "#4f4e4e",
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  PRESTUP TEPLA
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  STAVEBNÁ HĹBKA
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  od 0,98 W/m2K
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  76mm
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  MOŽN. LAMINÁCIE
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  PROFILOVÝ SYSTÉM
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  5 jednostranných fólií
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  5 komorový
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  POČET SKIEL
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  V-PERFECT
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  dvojsklo
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  nie
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  POČET TESNENÍ
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  ALUCLIP
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  2 celoobvodové
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  nie
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  FARBY JADIER
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                  marginBottom: 7,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  1 farba
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #161170",
              flex: 1.1,
              marginLeft: "2.5%",
            }}
          >
            <div
              style={{
                backgroundColor: "#cccfcd",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={hesta_text}
                    style={{ marginTop: 7 }}
                    //  width={windowSize.width - 15}
                    //  height={windowSize.height}
                  />
                </div>
                <div
                  style={{ fontSize: 40, fontWeight: "bold", color: "#161170" }}
                >
                  SMART+
                </div>
              </div>
              <div
                style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
              />
              <div style={{ backgroundColor: "white" }}>
                <img src={smartplus_window} width="100%" />
              </div>
              <div
                style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
              />
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    alignSelf: "flex-start",
                    display: "flex",
                    // flex: 2,
                    fontSize: 20,
                    marginRight: 20,
                    // justifyContent: "space-around",
                  }}
                >
                  Cenová náročnosť
                </div>
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    //  flex: 0.5,
                    // justifyContent: "space-around",
                  }}
                >
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "#161170",
                      //  flex: 1,
                      // alignSelf: "flex-start",
                      //marginRight: "70%",
                    }}
                  ></EuroIcon>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    // flex: 1,
                  }}
                >
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "#161170",
                    }}
                  ></EuroIcon>
                </div>
                <div style={{ display: "flex", alignSelf: "center" }}>
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "rgb(164, 165, 166, 1)",
                    }}
                  ></EuroIcon>
                </div>
                <div style={{ display: "flex", alignSelf: "center" }}>
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "rgb(164, 165, 166, 1)",
                    }}
                  ></EuroIcon>
                </div>
              </div>
              <div
                style={{
                  color: "black",
                  fontSize: 20,
                  alignSelf: "center",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 20,
                  height: 110,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Lepšie tepelno-izolačné vlastnosti dosiahnuté najmä použitím
                trojskla s rôznymi možnosťami dizajnu okien.
              </div>
            </div>
            <div
              style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
            />
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flex: 1,
                flexDirection: "column",
                marginLeft: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  //color: "#4f4e4e",
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  PRESTUP TEPLA
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  STAVEBNÁ HĹBKA
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  od 0,74 W/m2K
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  76mm
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  MOŽN. LAMINÁCIE
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  PROFILOVÝ SYSTÉM
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  60+ obojstranných fólií
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  5 komorový
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  POČET SKIEL
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  V-PERFECT
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  trojsklo
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  áno
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  POČET TESNENÍ
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  ALUCLIP
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  2 celoobvodové
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  nie
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  FARBY JADIER
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                  marginBottom: 7,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  2 farby
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #161170",
              flex: 1.1,
              marginLeft: "2.5%",
            }}
          >
            <div
              style={{
                backgroundColor: "#cccfcd",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={hesta_text}
                    style={{ marginTop: 7 }}
                    //  width={windowSize.width - 15}
                    //  height={windowSize.height}
                  />
                </div>
                <div
                  style={{ fontSize: 40, fontWeight: "bold", color: "#161170" }}
                >
                  HARMONY
                </div>
              </div>
              <div
                style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
              />
              <div style={{ backgroundColor: "white" }}>
                <img src={harmony_window} width="100%" />
              </div>
              <div
                style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
              />
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    alignSelf: "flex-start",
                    display: "flex",
                    // flex: 2,
                    fontSize: 20,
                    marginRight: 20,
                    // justifyContent: "space-around",
                  }}
                >
                  Cenová náročnosť
                </div>
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    //  flex: 0.5,
                    // justifyContent: "space-around",
                  }}
                >
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "#161170",
                      //  flex: 1,
                      // alignSelf: "flex-start",
                      //marginRight: "70%",
                    }}
                  ></EuroIcon>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    // flex: 1,
                  }}
                >
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "#161170",
                    }}
                  ></EuroIcon>
                </div>
                <div style={{ display: "flex", alignSelf: "center" }}>
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "#161170",
                    }}
                  ></EuroIcon>
                </div>
                <div style={{ display: "flex", alignSelf: "center" }}>
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "rgb(164, 165, 166, 1)",
                    }}
                  ></EuroIcon>
                </div>
              </div>
              <div
                style={{
                  color: "black",
                  fontSize: 20,
                  alignSelf: "center",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 20,
                  height: 110,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Vynikajúce tepelno-izolačné vlastnosti bez potreby špeciálneho
                dizajnu.
              </div>
            </div>
            <div
              style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
            />
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flex: 1,
                flexDirection: "column",
                marginLeft: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  //color: "#4f4e4e",
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  PRESTUP TEPLA
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  STAVEBNÁ HĹBKA
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  od 0,67 W/m2K
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  82mm
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  MOŽN. LAMINÁCIE
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  PROFILOVÝ SYSTÉM
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  5 jednostranných fólií
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  6 komorový
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  POČET SKIEL
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  V-PERFECT
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  trojsklo
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  nie
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  POČET TESNENÍ
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  ALUCLIP
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  2 + 1
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  nie
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  FARBY JADIER
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                  marginBottom: 7,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  1 farba
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #161170",
              flex: 1.1,
              marginRight: "5%",
              marginLeft: "2.5%",
            }}
          >
            <div
              style={{
                backgroundColor: "#cccfcd",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={hesta_text}
                    style={{ marginTop: 7 }}
                    //  width={windowSize.width - 15}
                    //  height={windowSize.height}
                  />
                </div>
                <div
                  style={{ fontSize: 40, fontWeight: "bold", color: "#161170" }}
                >
                  HARMONY+
                </div>
              </div>
              <div
                style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
              />
              <div style={{ backgroundColor: "white" }}>
                <img src={harmonyplus_window} width="100%" />
              </div>
              <div
                style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
              />
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    alignSelf: "flex-start",
                    display: "flex",
                    // flex: 2,
                    fontSize: 20,
                    marginRight: 20,
                    // justifyContent: "space-around",
                  }}
                >
                  Cenová náročnosť
                </div>
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    //  flex: 0.5,
                    // justifyContent: "space-around",
                  }}
                >
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "#161170",
                      //  flex: 1,
                      // alignSelf: "flex-start",
                      //marginRight: "70%",
                    }}
                  ></EuroIcon>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignSelf: "center",
                    // flex: 1,
                  }}
                >
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "#161170",
                    }}
                  ></EuroIcon>
                </div>
                <div style={{ display: "flex", alignSelf: "center" }}>
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "#161170",
                    }}
                  ></EuroIcon>
                </div>
                <div style={{ display: "flex", alignSelf: "center" }}>
                  <EuroIcon
                    style={{
                      display: "flex",
                      fontSize: 20,
                      color: "#161170",
                    }}
                  ></EuroIcon>
                </div>
              </div>
              <div
                style={{
                  color: "black",
                  fontSize: 20,
                  alignSelf: "center",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: 20,
                  height: 110,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                To najdokonalejšie z našej ponuky - ak hľadáte okná bez
                kompromisov v oblasti tepelno-izolačných vlastností ale aj
                dizajnu.
              </div>
            </div>
            <div
              style={{ backgroundColor: "#161170", height: 3, width: "100%" }}
            />
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flex: 1,
                flexDirection: "column",
                marginLeft: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  //color: "#4f4e4e",
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  PRESTUP TEPLA
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  STAVEBNÁ HĹBKA
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  od 0,67 W/m2K
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  82mm
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  MOŽN. LAMINÁCIE
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  PROFILOVÝ SYSTÉM
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  60+ obojstranných fólií
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  6 komorový
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  POČET SKIEL
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  V-PERFECT
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  trojsklo
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  áno
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  POČET TESNENÍ
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    //alignSelf: "center",
                    justifyContent: "flex-start",
                    //  alignContent: "center",
                  }}
                >
                  ALUCLIP
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  2 + 1
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  áno
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 15,
                  fontFamily: "Roboto",
                  color: "#161170",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    // marginRight: 30,
                    display: "flex",
                    flex: 1,
                    alignSelf: "center",
                    justifyContent: "flex-start",
                    // alignContent: "center",
                  }}
                >
                  FARBY JADIER
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  fontSize: 17,
                  fontFamily: "Roboto",
                  color: "#4f4e4e",
                  marginBottom: 7,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  }}
                >
                  4 farby
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 200 }}>
          <WebFooter />
        </div>
      </BrowserView>
      <MobileView>
        <MobileHeader />
      </MobileView>
    </div>
  );
}

export default PlasticWindowsScreen;
