import { BrowserView, MobileView } from "react-device-detect";
import WebHeader from "./WebHeader";
import MobileHeader from "./MobileHeader";
import WebFooter from "./WebFooter";

function ContactScreen() {
  return (
    <div>
      <BrowserView>
        <WebHeader />
        <div style={styles.underHeader} />
        <div style={{ backgroundColor: 'white', top: 0}}>
      <div style={styles.contact}>
        <div style={{ paddingTop: 20 }}>Ing. Stanislav Berta - JAST</div>
        <div>Mierová 64/2</div>
        <div>066 01 Humenné</div>
        <div style={{ marginTop: 10 }}>Tel.: +421 (0) 907 595 278</div>
        <div>Email: oknajast@azet.sk</div>
    </div>
    </div>
    <div style={{ position:'fixed',
left:0,
bottom:0,
right:0 }}>
    <WebFooter />
    </div>
      </BrowserView>
      <MobileView>
        <MobileHeader />
        <div style={styles.underHeader} />
        <div style={{ backgroundColor: 'white', top: 0}}>
      <div style={styles.contact}>
        <div style={{ paddingTop: 20 }}>Ing. Stanislav Berta - JAST</div>
        <div>Mierová 64/2</div>
        <div>066 01 Humenné</div>
        <div style={{ marginTop: 10 }}>Tel.: +421 (0) 907 595 278</div>
        <div>Email: oknajast@azet.sk</div>
    </div>
    </div>
    <div style={{ position:'fixed',
left:0,
bottom:0,
right:0 }}>
    <WebFooter />
    </div>
      </MobileView>
    </div>
  );
}

export default ContactScreen;

const styles = {
  contact: {
    display: "flex",
    alignSelf: "flex-start",
    flexDirection: "column",
    flex: 1,
    marginLeft: "20%", //400,
    fontFamily: "Helvetica Neue",
    color: "#161170",
    fontSize: '17',
    backgroundColor: 'white'
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    height: "10%", //200,
  //  backgroundColor: "#cccfcd",
    alignText: "flex-end",
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    flex: 1,
    //  marginTop: 275,
    //  paddingRight: 500,
    borderTop: "3px solid #161170",
  },
  underHeader: {
    backgroundColor: "#161170",
    height: 3,
  },
};
