import { render } from "@testing-library/react";
import { BrowserView, MobileView } from "react-device-detect";
import WebHeader from "./WebHeader";
import MobileHeader from "./MobileHeader";

function PlasticDoorsScreen() {
  return (
    <div>
      <BrowserView>
        <WebHeader />
      </BrowserView>
      <MobileView>
        <MobileHeader />
      </MobileView>
    </div>
  );
}

export default PlasticDoorsScreen;
