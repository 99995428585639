import { useNavigation } from "@react-navigation/native";
import Radium from "radium";
import { useEffect, useState } from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import image1 from "../images/image1.png";
import image2 from "../images/image2.png";
import image3 from "../images/image3.png";
import jast_logo from "../images/jast_logo.png";
import MenuIcon from "@material-ui/icons/Menu";
import WebHeader from "./WebHeader";
import MobileHeader from "./MobileHeader";
import WebFooter from "./WebFooter";

function HomeScreen() {
  const [showProducts, setShowProducts] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [imageNumber, setImageNumber] = useState(0);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const defaultProps = {
    center: { lat: 59.95, lng: 30.33 },
    zoom: 11,
  };

  const images = [image1, image2, image3];

  const navigation = useNavigation();

  useEffect(() => {
    const timer = setInterval(() => {
      imageNumber < 2 ? setImageNumber(imageNumber + 1) : setImageNumber(0);
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [imageNumber]);

  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [imageNumber]);

  /*const [width, setWidth] = useState < number > window.innerWidth;
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;*/

  /* useEffect(() => {
      const interval = setInterval(() => {
        imageNumber < 2 ? setImageNumber(imageNumber+1) : setImageNumber(0)
      }, 2000);
      return () => clearInterval(interval);
    }, []);*/

  return (
    <div>
      <BrowserView>
        <div>
          {/*<div style={styles.header}>
            <div style={styles.jastLogo}>
              <img src={jast_logo} width="120" height="80" />
            </div>
            <div style={[styles.headerButton, styles.headerText]} key="Home">
              Domov
            </div>
            <div
              style={styles.headerButton}
              onMouseLeave={() => setShowProducts(false)}
              onMouseEnter={() => setShowProducts(true)}
            >
              <div
                key="Products"
                style={
                  styles.headerText
                } 
              >
                Produkty
              </div>
              {showProducts && (
                <div style={styles.products}>
                  <div
                    style={styles.menu}
                    key="1"
                    onClick={() => {
                      navigation.navigate("Plastic windows");
                    }}
                  >
                    <span style={{ alignText: "center", alignItems: "center" }}>
                      Plastové okná
                    </span>
                  </div>
                  <div style={styles.menu} key="2">
                    Plastové dvere
                  </div>
                  <div style={styles.menu} key="3">
                    Hliníkové okná
                  </div>
                  <div style={styles.menu} key="4">
                    Hliníkové dvere
                  </div>
                  <div style={[styles.menu, { borderBottom: "0px" }]} key="5">
                    Plastové a hliníkové terasové dvere
                  </div>
                </div>
              )}
            </div>
            <div style={[styles.headerButton, styles.headerText]} key="Price">
              Cenová ponuka
            </div>
            <div style={[styles.headerButton, styles.headerText]} key="About">
              Kontakt
            </div>
                  </div>*/}
          <WebHeader />
          <div style={styles.underHeader} />
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <img
              src={images[imageNumber]}
              width={/*windowSize.width - 300*/ 1200}
              height={/*windowSize.height - 100*/ 700}
            />
          </div>
          <div style={styles.underHeader} />
          <div>
            <div
              style={{
                marginTop: 100,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 340,
                  height: 170,
                  border: "1px solid #161170",
                  marginBottom: 50,
                  alignItems: "center",
                  flex: 1,
                  marginLeft: 200,
                }}
              >
                <div style={styles.whyHeader}>SLOVENSKÉ VÝROBKY</div>
                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    flex: 1,
                    //  alignSelf: "center",
                    //alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      color: "#4f4e4e",
                      fontFamily: "Roboto",
                      fontSize: 18,
                      fontWeight: "bold",
                      marginTop: 15,
                    }}
                  >
                    <span>
                      Ponúkame kvalitné výrobky od slovenského výrobcu{" "}
                    </span>
                    <a href={"https://www.hesta.sk/sk/"}>HESTA</a>.
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 340,
                  height: 170,
                  border: "1px solid #161170",
                  marginBottom: 50,
                  alignItems: "center",
                  flex: 1,
                  marginLeft: 200,
                }}
              >
                <div style={styles.whyHeader}>ODBORNOSŤ</div>
                <div
                  style={{
                    textAlign: "center",
                    color: "#4f4e4e",
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: "bold",
                    alignItems: "center",
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <span>
                    Každému zákazníkovi garantujeme profesionálny prístup a rady
                    od našich skúsených odborníkov.
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 340,
                  height: 170,
                  border: "1px solid #161170",
                  marginBottom: 50,
                  alignItems: "center",
                  flex: 1,
                  marginLeft: 200,
                  marginRight: 200,
                }}
              >
                <div style={styles.whyHeader}>PRODUKTY</div>
                <div
                  style={{
                    textAlign: "center",
                    color: "#4f4e4e",
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: "bold",
                    alignItems: "center",
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <span>
                    Ponúkame široké spektrum produktov (plastové a hliníkové
                    okná a dvere, tieniaca technika, terasové dvere) v rôznych
                    farebných prevedeniach.
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 100,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flex: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 340,
                  height: 170,
                  border: "1px solid #161170",
                  marginBottom: 50,
                  marginRight: 100,
                  // marginLeft: 200,
                  // marginRight: 200,
                }}
              >
                <div style={styles.whyHeader}>SKÚSENOSTI</div>
                <div
                  style={{
                    textAlign: "center",
                    color: "#4f4e4e",
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: "bold",
                    alignItems: "center",
                    display: "flex",
                    flex: 1,
                    marginRight: 10,
                    marginLeft: 10,
                  }}
                >
                  <span>
                    Na trhu pôsobíme už viac ako 10 rokov. Za tento čas sme
                    nazbierali množstvo cenných skúseností, ktoré radi využijeme
                    vo Váš prospech.
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 340,
                  height: 170,
                  border: "1px solid #161170",
                  marginBottom: 50,
                  marginLeft: 100,
                  // marginLeft: 200,
                  // marginRight: 200,
                }}
              >
                <div style={styles.whyHeader}>GARANCIA SPOKOJNOSTI</div>
                <div
                  style={{
                    textAlign: "center",
                    color: "#4f4e4e",
                    fontFamily: "Roboto",
                    fontSize: 18,
                    fontWeight: "bold",
                    alignItems: "center",
                    display: "flex",
                    flex: 1,
                    marginRight: 10,
                    marginLeft: 10,
                  }}
                >
                  <span>
                    Na všetky naše produkty a služby poskytujeme nadštandardnú
                    záruku a každému zákazníkovi garantujeme, že od nás bude
                    odchádzať vždy spokojný. Po celom Slovensku máme stovky
                    spokojných zákazníkov.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div style={styles.footer}>
            <div style={styles.contact}>
              <div style={{ fontWeight: "bold" }}>KONTAKTNÉ INFORMÁCIE</div>
              <div style={{ paddingTop: 20 }}>Ing. Stanislav Berta - JAST</div>
              <div>Mierová 64/2</div>
              <div>066 01 Humenné</div>
              <div style={{ marginTop: 10 }}>Tel.: +421 (0) 907 595 278</div>
              <div>Email: oknajast@azet.sk</div>
            </div>
          </div>
          {/*showProducts && <div style={styles.products} onMouseLeave={() => setShowProducts(false)}></div>*/}
          {/*<Row>
            <Col sm={1}>Home</Col>
            <Col sm={1}>Produkty</Col>
            <Col sm={1}>Cenová ponuka</Col>
            <Col sm={1}>O nás</Col>
        </Row>*/}
          {/*<div style={{ height: '30%', width: '30%' }}>*/}
          {/* <div style={{ height: '200px', width: '300px' }}>
        <GoogleMapReact
            resetBoundsOnResize={true}
            bootstrapURLKeys={{ key: "AIzaSyCP3UfJlU2sO2tQoSI1zgZabhiK7zSDck0" }}
            style={{width: '400px', height: '300px'}}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            // size={ 100,100 }
            />
    </div>*/}
          {/*</div>*/}
          {/*<Map
          google={this.props.google}
          zoom={14}
          //style={mapStyles}
          initialCenter={
            {
              lat: -1.2884,
              lng: 36.8233
            }
          }
        />*/}
        </div>
      </BrowserView>
      <MobileView>
        <div style={{ backgroundColor: "white" }}>
          <MobileHeader />
          {/*<div style={styles.mobileHeader}>
            <div
              style={{
                flex: 1,
                alignSelf: "center",
                display: "flex",
              }}
            >
              <MenuIcon
                style={{
                  display: "flex",
                  fontSize: 40,
                  flex: 1,
                  alignSelf: "flex-start",
                  marginRight: "70%",
                }}
                onClick={() => {
                  setShowMobileMenu(!showMobileMenu);
                }}
              />
            </div>
            <div style={styles.mobileJastLogo}>
              <img src={jast_logo} width="50%" height="10%" />
            </div>
              </div>*/}
          <div style={styles.underHeader} />
          <div>
            <img
              src={images[imageNumber]}
              width={windowSize.width}
              height={windowSize.height / 2}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "80%",
              height: "200%",
              border: "1px solid rgb(164, 165, 166, 0.3)",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              marginLeft: "10%",
              marginTop: "10%",

              // marginBottom: 50,
            }}
          >
            <div style={{ fontSize: 20 }}>SLOVENSKÉ VÝROBKY</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "80%",
              height: "200%",
              border: "1px solid rgb(164, 165, 166, 0.3)",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              marginLeft: "10%",
              marginTop: "10%",

              // marginBottom: 50,
            }}
          >
            <div style={{ fontSize: 20 }}>ODBORNOSŤ</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "80%",
              height: "200%",
              border: "1px solid rgb(164, 165, 166, 0.3)",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              marginLeft: "10%",
              marginTop: "10%",

              // marginBottom: 50,
            }}
          >
            <div style={{ fontSize: 20 }}>PRODUKTY</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "80%",
              height: "200%",
              border: "1px solid rgb(164, 165, 166, 0.3)",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              marginLeft: "10%",
              marginTop: "10%",

              // marginBottom: 50,
            }}
          >
            <div style={{ fontSize: 20 }}>SKÚSENOSTI</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "80%",
              height: "200%",
              border: "1px solid rgb(164, 165, 166, 0.3)",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              marginLeft: "10%",
              marginTop: "10%",
              marginBottom: "20%",
            }}
          >
            <div style={{ fontSize: 20 }}>GARANCIA SPOKOJNOSTI</div>
          </div>
          <WebFooter />

          {/*showMobileMenu && (
            <div
              style={{
                alignSelf: "flex-start",
                justifyContent: "flex-start",
                alignContent: "flex-start",
                display: "flex",
                flexDirection: "column",
                width: "50%",
                backgroundColor: "#cccfcd",
                // marginLeft: "60%",
                position: "absolute",
                top: "5.9%",
              }}
            >
              <div
                key="Home"
                style={{
                  alignSelf: "flex-start",
                  fontSize: 20,
                  color: "#161170",
                  width: "100%",
                  borderBottom: "1px solid #161170",
                }}
              >
                Domov
              </div>
              <div
                key="Products"
                style={{
                  alignSelf: "flex-start",
                  fontSize: 20,
                  color: "#161170",
                  width: "100%",
                  borderBottom: "1px solid #161170",
                }}
                onClick={() => {
                  setShowProducts(!showProducts);
                }}
              >
                Produkty
              </div>
              {showProducts && (
                <div>
                  <div
                    key="1"
                    onClick={() => {
                      navigation.navigate("Plastic windows");
                    }}
                    style={{
                      marginLeft: "5%",
                      height: 30,
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span
                      style={{
                        alignText: "center",
                        alignItems: "center",
                      }}
                    >
                      Plastové okná
                    </span>
                  </div>
                  <div
                    key="2"
                    style={{
                      marginLeft: "5%",
                      height: 30,
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    Plastové dvere
                  </div>
                  <div
                    key="3"
                    style={{
                      marginLeft: "5%",
                      height: 30,
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    Hliníkové okná
                  </div>
                  <div
                    key="4"
                    style={{
                      marginLeft: "5%",
                      height: 30,
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    Hliníkové dvere
                  </div>
                  <div
                    key="5"
                    style={{
                      marginLeft: "5%",
                      height: 30,
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    Plastové a hliníkové terasové dvere
                  </div>
                </div>
              )}
              <div
                key="Price"
                style={{
                  alignSelf: "flex-start",
                  fontSize: 20,
                  color: "#161170",
                  width: "100%",
                  borderBottom: "1px solid #161170",
                }}
              >
                Cenová ponuka
              </div>
              <div
                key="About"
                style={{
                  alignSelf: "flex-start",
                  fontSize: 20,
                  color: "#161170",
                  width: "100%",
                }}
              >
                Kontakt
              </div>
            </div>
              )*/}
        </div>
      </MobileView>
    </div>
  );
}

HomeScreen = Radium(HomeScreen);

const styles = {
  contact: {
    display: "flex",
    alignSelf: "flex-start",
    flexDirection: "column",
    flex: 1,
    marginLeft: "20%", //400,
    fontFamily: "Helvetica Neue",
    color: "#161170",
    marginTop: "1%", //20,
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    height: "10%", //200,
    backgroundColor: "#cccfcd",
    alignText: "flex-end",
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    flex: 1,
    //  marginTop: 275,
    //  paddingRight: 500,
    borderTop: "3px solid #161170",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    height: "4%", //70,
    backgroundColor: "#cccfcd",
    alignText: "flex-end",
    justifyContent: "flex-end",
    paddingRight: "20%", //500,
  },
  headerButton: {
    alignSelf: "flex-end",
    //  marginBottom: 10,
    fontFamily: "Helvetica Neue",
    fontSize: 16,
    paddingRight: "0.5%", //10,
    paddingLeft: "0.5%", //10,
    ":hover": {
      color: "#585859",
      cursor: "pointer",
    },
    fontWeight: "bold",
    color: "#161170",
  },
  headerText: {
    marginBottom: 10,
  },
  homeButton: {
    alignSelf: "flex-end",
    fontSize: 20,
    paddingRight: "1%", //20,
    paddingLeft: "1%", //20,
    ":hover": {
      color: "white",
      cursor: "pointer",
    },
  },
  jastLogo: {
    display: "flex",
    alignSelf: "flex-start",
    flex: 1,
    marginLeft: "10%", //100,
    marginTop: "-0.25%", //-5,
  },
  menu: {
    fontSize: 12,
    // borderBottom: '1px solid #cfcfd1',
    fontFamily: "Helvetica Neue",
    color: "black",
    height: "3em",
    flex: 1,
    display: "flex",
    ":hover": {
      backgroundColor: "#76a8cf",
      cursor: "pointer",
    },
    // justifyContent: 'center',
    alignItems: "center",
    borderBottom: "1px solid #161170",
    // borderColor: 'black',
    // borderStyle: 'solid'
  },
  mobileHeader: {
    display: "flex",
    flexDirection: "row",
    height: "4%", //70,
    backgroundColor: "#cccfcd",
    justifyContent: "center",
    alignItems: "center",
    // paddingRight: "20%", //500,
  },
  mobileJastLogo: {
    display: "flex",
    alignSelf: "flex-start",
    flex: 1,
    marginRight: "20%",
    // marginLeft: "10%", //100,
    marginTop: "-0.25%",
  },
  priceButton: {
    alignSelf: "flex-end",
    fontSize: 20,
    paddingRight: "1%", //20,
    paddingLeft: "1%", //20,
    ":hover": {
      color: "white",
      cursor: "pointer",
    },
  },
  productsButton: {
    alignSelf: "flex-end",
    fontSize: 20,
    paddingRight: "1%", //20,
    paddingLeft: "1%", //20,
    ":hover": {
      color: "white",
      cursor: "pointer",
    },
  },
  products: {
    width: "11%",
    //height: '10em',
    //  backgroundColor: 'red',
    // marginLeft: '60%',
    position: "absolute",
    top: "4%",
    backgroundColor: "#cccfcd",
    //  border: '1px solid red',
    borderTop: "3px solid #76a8cf",
    // borderLeft: '1px solid #cfcfd1'
  },
  underHeader: {
    backgroundColor: "#161170",
    height: 3,
  },
  whyHeader: {
    fontFamily: "Helvetica Neue",
    marginTop: "1%", //20,
    fontSize: 20,
    color: "#161170",
    fontWeight: "bold",
    alignSelf: "center",
    marginTop: 10,
    marginBottom: 10,
  },
};

export default HomeScreen;
