import { useEffect, useState } from "react";
import jast_logo from "../images/jast_logo.png";
import Radium from "radium";
import { useNavigation } from "@react-navigation/native";

function WebHeader() {
  const [showProducts, setShowProducts] = useState(false);

  const navigation = useNavigation();

  return (
    <div style={styles.header}>
      <div style={styles.jastLogo}>
        <img src={jast_logo} width="120" height="80" />
      </div>
      <div
        style={[styles.headerButton, styles.headerText]}
        key="Home"
        onClick={() => {
          navigation.navigate("Home");
        }}
      >
        Domov
      </div>
      <div
        style={styles.headerButton}
        onMouseLeave={() => setShowProducts(false)}
        onMouseEnter={() => setShowProducts(true)}
      >
        <div
          key="Products"
          style={
            styles.headerText
          } /*onMouseEnter={() => setShowProducts(true)}*/ /*onMouseLeave={() => setShowProducts(false)}*/
        >
          Produkty
        </div>
        {showProducts && (
          <div style={styles.products}>
            {/*} <div style={styles.underHeader} />*/}
            <div
              style={styles.menu}
              key="1"
              onClick={() => {
                navigation.navigate("Plastic windows");
              }}
            >
              <span style={{ alignText: "center", alignItems: "center" }}>
                Plastové okná
              </span>
            </div>
            <div
              style={styles.menu}
              key="2"
              onClick={() => {
                navigation.navigate("Plastic doors");
              }}
            >
              Plastové dvere
            </div>
            <div
              style={styles.menu}
              key="3"
              onClick={() => {
                navigation.navigate("Aluminum windows");
              }}
            >
              Hliníkové okná
            </div>
            <div
              style={styles.menu}
              key="4"
              onClick={() => {
                navigation.navigate("Aluminum doors");
              }}
            >
              Hliníkové dvere
            </div>
            <div
              style={[styles.menu, { borderBottom: "0px" }]}
              key="5"
              onClick={() => {
                navigation.navigate("Terrace doors");
              }}
            >
              Plastové a hliníkové terasové dvere
            </div>
          </div>
        )}
      </div>
     {/*  <div
        style={[styles.headerButton, styles.headerText]}
        key="Price"
        onClick={() => {
          navigation.navigate("Price offer");
        }}
      >
        Cenová ponuka
      </div> */}
      <div
        style={[styles.headerButton, styles.headerText]}
        key="About"
        onClick={() => {
          navigation.navigate("Contact");
        }}
      >
        Kontakt
      </div>
    </div>
  );
}

WebHeader = Radium(WebHeader);

const styles = {
  contact: {
    display: "flex",
    alignSelf: "flex-start",
    flexDirection: "column",
    flex: 1,
    marginLeft: "20%", //400,
    fontFamily: "Helvetica Neue",
    color: "#161170",
    marginTop: "1%", //20,
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    height: "10%", //200,
    backgroundColor: "#cccfcd",
    alignText: "flex-end",
    justifyContent: "flex-end",
    alignSelf: "flex-end",
    flex: 1,
    //  marginTop: 275,
    //  paddingRight: 500,
    borderTop: "3px solid #161170",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    height: "4%", //70,
    backgroundColor: "#cccfcd",
    alignText: "flex-end",
    justifyContent: "flex-end",
    paddingRight: "5%", //500,
  },
  headerButton: {
    alignSelf: "flex-end",
    //  marginBottom: 10,
    fontFamily: "Helvetica Neue",
    fontSize: 16,
    paddingRight: "0.5%", //10,
    paddingLeft: "0.5%", //10,
    ":hover": {
      color: "#585859",
      cursor: "pointer",
    },
    fontWeight: "bold",
    color: "#161170",
  },
  headerText: {
    marginBottom: 10,
  },
  homeButton: {
    alignSelf: "flex-end",
    fontSize: 20,
    paddingRight: "1%", //20,
    paddingLeft: "1%", //20,
    ":hover": {
      color: "white",
      cursor: "pointer",
    },
  },
  jastLogo: {
    display: "flex",
    alignSelf: "flex-start",
    flex: 1,
    marginLeft: "10%", //100,
    marginTop: "-0.25%", //-5,
  },
  menu: {
    fontSize: 12,
    // borderBottom: '1px solid #cfcfd1',
    fontFamily: "Helvetica Neue",
    color: "black",
    height: "3em",
    flex: 1,
    display: "flex",
    ":hover": {
      backgroundColor: "#76a8cf",
      cursor: "pointer",
    },
    // justifyContent: 'center',
    alignItems: "center",
    borderBottom: "1px solid #161170",
    // borderColor: 'black',
    // borderStyle: 'solid'
  },
  mobileHeader: {
    display: "flex",
    flexDirection: "row",
    height: "4%", //70,
    backgroundColor: "#cccfcd",
    justifyContent: "center",
    alignItems: "center",
    // paddingRight: "20%", //500,
  },
  mobileJastLogo: {
    display: "flex",
    alignSelf: "flex-start",
    flex: 1,
    marginRight: "20%",
    // marginLeft: "10%", //100,
    marginTop: "-0.25%",
  },
  priceButton: {
    alignSelf: "flex-end",
    fontSize: 20,
    paddingRight: "1%", //20,
    paddingLeft: "1%", //20,
    ":hover": {
      color: "white",
      cursor: "pointer",
    },
  },
  productsButton: {
    alignSelf: "flex-end",
    fontSize: 20,
    paddingRight: "1%", //20,
    paddingLeft: "1%", //20,
    ":hover": {
      color: "white",
      cursor: "pointer",
    },
  },
  products: {
    width: "11%",
    //height: '10em',
    //  backgroundColor: 'red',
    // marginLeft: '60%',
    position: "absolute",
    // top: "4%",
    backgroundColor: "#cccfcd",
    //  border: '1px solid red',
    borderTop: "3px solid #76a8cf",
    // borderLeft: '1px solid #cfcfd1'
  },
  underHeader: {
    backgroundColor: "#161170",
    height: 3,
  },
  whyHeader: {
    fontFamily: "Helvetica Neue",
    marginTop: "1%", //20,
    fontSize: 20,
    color: "#161170",
    fontWeight: "bold",
    alignSelf: "center",
  },
};

export default WebHeader;
